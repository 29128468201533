import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { TagInput } from '@class101/ui';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Example = makeShortcode("Example");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "taginput"
    }}>{`TagInput`}</h1>
    <p>{`문자열을 입력받는 컴포넌트입니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={TagInput} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'() => {\n  class Example extends React.Component {\n    constructor(props) {\n      super(props)\n      this.state = {\n        value: [],\n      }\n    }\n    handleChange(newValue) {\n      this.setState({ value: newValue })\n    }\n    handleSubmit(e) {\n      e.preventDefault()\n    }\n    render() {\n      return (\n        <TagInput\n          separator=\",\"\n          value={this.state.value}\n          onChange={value => this.handleChange(value)}\n          allowMessage=\"Enter와 \',\'로 구분합니다.\"\n        />\n      )\n    }\n  }\n  return <Example />\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TagInput,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        class Example extends React.Component {
          constructor(props) {
            super(props);
            this.state = {
              value: []
            };
          }

          handleChange(newValue) {
            this.setState({
              value: newValue
            });
          }

          handleSubmit(e) {
            e.preventDefault();
          }

          render() {
            return <TagInput separator="," value={this.state.value} onChange={value => this.handleChange(value)} allowMessage="Enter와 ','로 구분합니다." mdxType="TagInput" />;
          }

        }

        return <Example mdxType="Example" />;
      }}
    </Playground>
    <p>{`5000개 이상일 경우 하나의 태그로 보여지며 해당 태그를 삭제하면 5000여개의 모든 값이 삭제됩니다.`}</p>
    <Playground __position={2} __code={'() => {\n  const defaultValue = () => {\n    const array = []\n    for (let i = 0; i < 5300; i++) {\n      array.push(`${i}-1010`)\n    }\n    return array\n  }\n  class Example extends React.Component {\n    constructor(props) {\n      super(props)\n      this.state = {\n        value: defaultValue(),\n      }\n    }\n    handleChange(newValue) {\n      this.setState({ value: newValue })\n    }\n    handleSubmit(e) {\n      e.preventDefault()\n    }\n    render() {\n      return (\n        <TagInput\n          separator=\",\"\n          value={this.state.value}\n          onChange={value => this.handleChange(value)}\n          allowMessage=\"Enter와 \',\'로 구분합니다.\"\n        />\n      )\n    }\n  }\n  return <Example />\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TagInput,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const defaultValue = () => {
          const array = [];

          for (let i = 0; i < 5300; i++) {
            array.push(`${i}-1010`);
          }

          return array;
        };

        class Example extends React.Component {
          constructor(props) {
            super(props);
            this.state = {
              value: defaultValue()
            };
          }

          handleChange(newValue) {
            this.setState({
              value: newValue
            });
          }

          handleSubmit(e) {
            e.preventDefault();
          }

          render() {
            return <TagInput separator="," value={this.state.value} onChange={value => this.handleChange(value)} allowMessage="Enter와 ','로 구분합니다." mdxType="TagInput" />;
          }

        }

        return <Example mdxType="Example" />;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      